<template>
  <site-menu />
  <slot></slot>

  <site-rodape />
</template>

<script>
export default {
  name: "ClinicaLayout",
};
</script>
